import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Stack, Typography } from '@mui/material';
import { insertColor, insertOmbre, setColorToAllTheeth, } from '../../../../redux/slices/prothese/protheseSlice';
import { dispatch, useSelector } from '../../../../redux/store';
export default function RightOptionsSection({ options, filter, currentPrice, selectedDent, }) {
    const prothese = useSelector((state) => state.dent);
    const dent = prothese.dents[0];
    return (_jsxs(Box, { sx: {
            marginTop: '28px',
            width: '100%',
            overflow: 'auto',
            backgroundColor: 'transparent !important',
            border: '1px solid #F1F3F7',
            padding: '22px',
            borderRadius: 2,
        }, children: [_jsx(Typography, { variant: "body1", color: 'text.secondary', sx: { fontWeight: '600', fontSize: '18px', marginBottom: '30px', color: '#6D758F' }, children: "Nuance de couleur :" }), _jsx(Box, { sx: {
                    display: 'flex',
                    alignItems: 'center',
                    gap: '20px',
                    flexWrap: 'wrap',
                    background: '#F8FAFF',
                    borderRadius: 3,
                    padding: '23px 32px',
                }, children: options?.map((option) => {
                    return option?.options?.map((op, index) => {
                        return (_jsxs(Stack, { sx: {
                                display: 'flex',
                                alignItems: 'center',
                                gap: '10px',
                                width: '40px',
                                cursor: 'pointer',
                                padding: '5px',
                                borderRadius: '2px',
                                background: dent?.color === op?.color
                                    ? 'linear-gradient(271deg, #2788E7 0%, #5EC4E0 107.92%)'
                                    : 'transparent',
                            }, onClick: () => {
                                dispatch(insertColor({ name: selectedDent.toString(), color: op?.color }));
                                dispatch(insertOmbre({ name: selectedDent.toString(), ombre: op?.name }));
                                dispatch(setColorToAllTheeth({ color: op?.color, ombre: op?.name }));
                            }, children: [_jsx("svg", { xmlns: "http://www.w3.org/2000/svg", width: "29", height: "34", viewBox: "0 0 29 34", fill: op?.color, children: _jsx("path", { d: "M23.8466 28.0005C21.514 34.1414 14.635 33.8506 14.527 33.8506C14.4298 33.8613 7.53993 34.1522 5.20732 28.0005C2.85312 21.8058 -5.84019 0.0325279 8.38226 0.0325279L14.5054 0.000205994H14.527H14.5486L20.6717 0.0325279C34.9049 0.0325279 26.2116 21.8058 23.8466 28.0005Z", fill: op?.color }) }), _jsx(Typography, { variant: "caption", color: dent?.color === op?.color ? 'white' : 'black', sx: { textAlign: 'center' }, children: op?.name })] }));
                    });
                }) })] }));
}
